import * as React from "react";
import { Link } from "gatsby";
import 'bootstrap/dist/css/bootstrap.css';
import NavigatioBar from '../components/navigationBar/mainMenu/navigationBar';
import Footer from '../components/footer/footer';
import GlobalStyles from '../styles/GlobalStyles';

const NotFoundPage = () => {
  return (<>
    <GlobalStyles />
    <NavigatioBar/>
    <main className="Container">
      <h1 className="Header">Page not found</h1>
      <p>
        Sorry 😔, we couldn’t find what you were looking for.
        <br/>
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
    <Footer/>
    </>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
